<template>
 <sdPageHeader title="Taken">
 </sdPageHeader>
 <Main>
  <a-row :gutter="25">
   <a-col :lg="6" :md="7" :xl="5" :xs="24">
    <template v-if="responsive > 767">
     <SidebarWrap class="mb-30">
      <div class="sDash_taskApp-sidebar">
       <sdButton
        class="sDash_btn-add"
        raised
        size="large"
        type="primary"
        @click="showModal"
       >
        <sdFeatherIcons size="16" type="plus"/>
        Taak Toevoegen
       </sdButton>
       <ul class="sDash_taskApp-sidebar__nav">
        <li class="sDash_taskApp-sidebar__nav--item">
         <router-link
          :to="`${path}/all`"
          class="sDash_taskApp-sidebar__nav--link"
         >
                    <span class="nav-item-icon">
                      <sdFeatherIcons size="16" type="edit"/>
                    </span>
          <span class="nav-item-text">Alle taken</span>
         </router-link>
        </li>
        <li class="sDash_taskApp-sidebar__nav--item">
         <router-link
          :to="`${path}/favorites`"
          class="sDash_taskApp-sidebar__nav--link"
         >
                    <span class="nav-item-icon">
                      <sdFeatherIcons size="16" type="flag"/>
                    </span>
          <span class="nav-item-text">Urgent</span>
         </router-link>
        </li>
        <li class="sDash_taskApp-sidebar__nav--item">
         <router-link
          :to="`${path}/completed`"
          class="sDash_taskApp-sidebar__nav--link"
         >
                    <span class="nav-item-icon">
                      <sdFeatherIcons size="16" type="check"/>
                    </span>
          <span class="nav-item-text">Afgerond</span>
         </router-link>
        </li>
       </ul>
      </div>
     </SidebarWrap>
     <sdModal
      :footer="null"
      :onCancel="handleCancel"
      :type="modalType"
      :visible="visible"
      class="sDash_addTask-modal"
      title="Taak Toevoegen"
     >
      <div class="sDash_addTask-modal-inner">
       <BasicFormWrapper>
        <a-form
         :layout="formState.layout"
         :model="formState"
         name="add-task"
         @finish="handleAddTask"
        >
         <a-form-item name="title">
          <a-input
           v-model:value="formState.title"
           placeholder="Title"
          />
         </a-form-item>

         <a-form-item name="description">
          <a-textarea
           v-model:value="formState.description"
           :rows="4"
           placeholder="Add Description"
          />
         </a-form-item>
         <div class="sDash-modal-actions">
          <sdButton
           key="cancel"
           outlined
           size="small"
           type="light"
           @click="handleCancel"
          >
           Cancel
          </sdButton>
          <sdButton
           key="submit"
           htmlType="submit"
           size="small"
           type="primary"
          >
           Add Task
          </sdButton>
         </div>
        </a-form>
       </BasicFormWrapper>
      </div>
     </sdModal>
    </template>

    <FixedSidebar v-else :class="collapsed ? 'show' : 'hide'">
     <a class="trigger-close" to="#" type="link" @click="toggleCollapsed">
      <sdFeatherIcons type="x"/>
     </a>
     <SidebarWrap class="mb-30">
      <div class="sDash_taskApp-sidebar">
       <sdButton
        class="sDash_btn-add"
        raised
        size="large"
        type="primary"
        @click="showModal"
       >
        <sdFeatherIcons size="16" type="plus"/>
        Add Task
       </sdButton>
       <ul class="sDash_taskApp-sidebar__nav">
        <li class="sDash_taskApp-sidebar__nav--item">
         <router-link
          :to="`${path}/all`"
          class="sDash_taskApp-sidebar__nav--link"
         >
                    <span class="nav-item-icon">
                      <sdFeatherIcons size="16" type="edit"/>
                    </span>
          <span class="nav-item-text">All</span>
         </router-link>
        </li>
        <li class="sDash_taskApp-sidebar__nav--item">
         <router-link
          :to="`${path}/favorites`"
          class="sDash_taskApp-sidebar__nav--link"
         >
                    <span class="nav-item-icon">
                      <sdFeatherIcons size="16" type="star"/>
                    </span>
          <span class="nav-item-text">Favorite</span>
         </router-link>
        </li>
        <li class="sDash_taskApp-sidebar__nav--item">
         <router-link
          :to="`${path}/completed`"
          class="sDash_taskApp-sidebar__nav--link"
         >
                    <span class="nav-item-icon">
                      <sdFeatherIcons size="16" type="check"/>
                    </span>
          <span class="nav-item-text">Completed</span>
         </router-link>
        </li>
       </ul>
      </div>
     </SidebarWrap>
    </FixedSidebar>
   </a-col>
   <a-col :lg="18" :md="17" :xl="19" :xs="24">
    <div
     v-if="responsive <= 767"
     class="sidebar-trier-wrap text-center mb-30"
    >
     <sdButton
      :style="{ marginTop: 0 }"
      class="sidebar-trigger"
      type="link"
      @click="toggleCollapsed"
     >
      <sdFeatherIcons :type="collapsed ? 'align-left' : 'align-right'"/>
     </sdButton>
    </div>

    <router-view name="child"></router-view>
   </a-col>
  </a-row>
  <span
   :class="collapsed ? 'overlay-dark show' : 'overlay-dark'"
   role="button"
   tabIndex="0"
   @click="toggleCollapsed"
  />
 </Main>
</template>
<script>
import {FixedSidebar, SidebarWrap} from "./style";
import {BasicFormWrapper, Main} from "../styled";
import {useStore} from "vuex";
import {computed, defineComponent, reactive, ref, watchEffect} from "vue";
import {useRoute} from "vue-router";

const Task = defineComponent({
 name: "Task",
 components: {FixedSidebar, SidebarWrap, Main, BasicFormWrapper},
 setup() {
  const {state, dispatch} = useStore();
  const taskData = computed(() => state.task.data);
  const responsive = ref(0);
  const collapsed = ref(false);
  const visible = ref(false);
  const modalType = ref("primary");
  const {matched} = useRoute();
  const {path} = matched[1];

  const showModal = () => {
   visible.value = true;
   collapsed.value = false;
  };
  const handleCancel = () => {
   visible.value = false;
  };

  const formState = reactive({
   title: "",
   description: "",
   layout: "vertical",
  });

  const handleAddTask = (values) => {
   handleCancel();
   const arrayData = [];
   taskData.value.map((data) => {
    return arrayData.push(data.id);
   });
   const max = Math.max(...arrayData);
   dispatch("taskAddData", [
    ...taskData.value,
    {
     ...values,
     id: max + 1,
     favourite: false,
     completed: false,
    },
   ]);
  };

  watchEffect(() => {
   function updateSize() {
    const width = window.innerWidth;
    responsive.value = width;
   }

   window.addEventListener("resize", updateSize);
   updateSize();
   // return () => window.removeEventListener('resize', updateSize);
  });

  const toggleCollapsed = () => {
   collapsed.value = !collapsed.value;
  };

  return {
   taskData,
   responsive,
   collapsed,
   visible,
   modalType,
   showModal,
   handleCancel,
   handleAddTask,
   toggleCollapsed,
   formState,
   path,
  };
 },
});

export default Task;
</script>
